import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosAuth from '../../utils/axiosAuth';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  feeClassList: [],
  feeClasses: []
};

const slice = createSlice({
  name: 'feeClass',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // DELETE feeClass
    deleteFeeClass(state, action) {
      const deleteFeeClass = filter(state.employeeList, (employee) => employee._id !== action.payload);
      state.feeClassList = deleteFeeClass;
    },

    // GET All feeClass
    getFeeClassListSuccess(state, action) {
      state.isLoading = false;
      state.feeClassList = action.payload;
    },

    getFeeClassesSuccess(state, action) {
      state.isLoading = false;
      state.feeClasses = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteFeeClass } = slice.actions;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getFeeClassList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.get('feeCollectionDash/fetch_class');
      dispatch(slice.actions.getFeeClassListSuccess(response.data.classes));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFeeClasses() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.get('fee_classes/fetch_all');
      dispatch(slice.actions.getFeeClassesSuccess(response.data.classes));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
