import { Navigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';

// layouts
import CreateAccountLayout from '../../layouts/CreateAccountLayout';
// Client Type Component
import ClientTypeComponent from '../../components/create-account/ClientTypeComponent';

// components
import Page from '../../components/Page';
import useAuth from '../../hooks/useAuth';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 760,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function ClientType() {
  const { user } = useAuth();

  if (user.company != null) {
    return <Navigate to="/dashboard/analytics" />;
  }

  return (
    <RootStyle title="Create Account | Hela">
      <CreateAccountLayout>Powered By Pinbolt Hosting</CreateAccountLayout>

      <Container maxWidth="md">
        <ContentStyle>
          <ClientTypeComponent />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
