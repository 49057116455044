// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_CREATE = '/create-account';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_CREATE = {
  root: ROOTS_CREATE,
  company: path(ROOTS_CREATE, '/company'),
  individial: path(ROOTS_CREATE, '/individual')
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },

  wallet: {
    root: path(ROOTS_DASHBOARD, '/wallet')
  },

  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },

  employee: {
    root: path(ROOTS_DASHBOARD, '/employee'),
    exit: path(ROOTS_DASHBOARD, '/employee/exit'),
    list: path(ROOTS_DASHBOARD, '/employee/list'),
    // new: path(ROOTS_DASHBOARD, '/employee/new'),
    edit: path(ROOTS_DASHBOARD, `/employee/edit`),
    // account: path(ROOTS_DASHBOARD, '/employee/account')
    profile: path(ROOTS_DASHBOARD, '/employee/view')
  },

  deduction: {
    root: path(ROOTS_DASHBOARD, '/deduction'),
    helb: path(ROOTS_DASHBOARD, '/deduction/helb'),
    loan: path(ROOTS_DASHBOARD, '/deduction/loan'),
    fees: path(ROOTS_DASHBOARD, '/deduction/fees')
  },

  payroll: {
    root: path(ROOTS_DASHBOARD, '/payroll'),
    list: path(ROOTS_DASHBOARD, '/payroll/list'),
    past: path(ROOTS_DASHBOARD, '/payroll/past'),
    payslip: path(ROOTS_DASHBOARD, '/payroll/payslip'),
    p9form: path(ROOTS_DASHBOARD, '/payroll/p9form'),
    profile: path(ROOTS_DASHBOARD, '/payroll/view')
  },

  salaryAdvance: {
    root: path(ROOTS_DASHBOARD, '/salaryAdvance'),
    list: path(ROOTS_DASHBOARD, '/salaryAdvance/list'),
    profile: path(ROOTS_DASHBOARD, '/salaryAdvance/profile')
  },

  paymentsUser: {
    root: path(ROOTS_DASHBOARD, '/payments'),
    list: path(ROOTS_DASHBOARD, '/paymentsUser/list')
  },

  reports: {
    root: path(ROOTS_DASHBOARD, '/reports'),
    housingLevy: path(ROOTS_DASHBOARD, '/reports/housingLevy'),
    nhif: path(ROOTS_DASHBOARD, '/reports/nhif'),
    nssf: path(ROOTS_DASHBOARD, '/reports/nssf'),
    paye: path(ROOTS_DASHBOARD, '/reports/paye')
    // p9: path(ROOTS_DASHBOARD, '/reports/p9')
  },

  feeCollection: {
    root: path(ROOTS_DASHBOARD, '/feeCollection'),
    overview: path(ROOTS_DASHBOARD, '/feeCollection/overview'),
    payments: path(ROOTS_DASHBOARD, '/feeCollection/payments'),
    classes: path(ROOTS_DASHBOARD, '/feeCollection/classes'),
    students: path(ROOTS_DASHBOARD, '/feeCollection/students'),
    archives: path(ROOTS_DASHBOARD, '/feeCollection/archives'),
    deletedStudents: path(ROOTS_DASHBOARD, '/feeCollection/deletedStudents'),
    feeStructures: path(ROOTS_DASHBOARD, '/feeCollection/feeStructures'),
    feeStructuresView: path(ROOTS_DASHBOARD, '/feeCollection/feeStructures/view'),
    feeStructuresTermView: path(ROOTS_DASHBOARD, '/feeCollection/feeStructures/view/term'),
    settings: path(ROOTS_DASHBOARD, '/feeCollection/settings'),
    studentsProfile: path(ROOTS_DASHBOARD, '/feeCollection/students/view'),
    archivesProfile: path(ROOTS_DASHBOARD, '/feeCollection/archives/view'),
    deletedProfile: path(ROOTS_DASHBOARD, '/feeCollection/deletedStudents/view'),
    studentsEdit: path(ROOTS_DASHBOARD, '/feeCollection/students/edit'),
    classesProfile: path(ROOTS_DASHBOARD, '/feeCollection/classes/view')
  },

  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
