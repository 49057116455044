import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosAuth from '../../utils/axiosAuth';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  helbList: []
};

const slice = createSlice({
  name: 'helb',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // DELETE Payroll
    deleteHelb(state, action) {
      const deleteHelb = filter(state.helbList, (employee) => employee._id !== action.payload);
      state.helbList = deleteHelb;
    },

    // GET All Salary Advances
    getHelbListSuccess(state, action) {
      state.isLoading = false;
      state.helbList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteHelb } = slice.actions;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getHelbList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.get('helb/fetch_all');
      dispatch(slice.actions.getHelbListSuccess(response.data.helbs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
