import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosAuth from '../../utils/axiosAuth';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  employee: [],
  employeeList: [],
  employeeListInactive: []
};

const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Employee
    getEmployeesSuccess(state, action) {
      state.isLoading = false;
      state.employees = action.payload;
    },

    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const followerId = action.payload;

      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed
          };
        }
        return follower;
      });

      state.followers = handleToggle;
    },

    // GET MANAGE USERS
    getEmployeeListSuccess(state, action) {
      state.isLoading = false;
      state.employeeList = action.payload;
    },

    // GET MANAGE USERS
    getEmployeeListInactiveSuccess(state, action) {
      state.isLoading = false;
      state.employeeListInactive = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow } = slice.actions;

// ----------------------------------------------------------------------

export function getProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.get('/api/user/profile');
      dispatch(slice.actions.getProfileSuccess(response.data.profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEmployeeList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.get('employee/fetch_all');

      dispatch(slice.actions.getEmployeeListSuccess(response.data.employees));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEmployeeListInactive() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.get('employee/fetch_inactive');
      dispatch(slice.actions.getEmployeeListInactiveSuccess(response.data.employees));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteEmployee(id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.put(`employee/delete/${id}`, data);
      await dispatch(slice.actions.getEmployeeListSuccess(response.data.employees));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editPersonalinfo(id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.put(`employee/edit/personalInformation/${id}`, data);
      await dispatch(slice.actions.getEmployeeListSuccess(response.data.employees));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editEmploymentProfile(id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.put(`employee/edit/employmentProfile/${id}`, data);
      await dispatch(slice.actions.getEmployeeListSuccess(response.data.employees));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editSalaryInfo(id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.put(`employee/edit/salaryInformation/${id}`, data);
      await dispatch(slice.actions.getEmployeeListSuccess(response.data.employees));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editStatutoryInfo(id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.put(`employee/edit/statutoryInformation/${id}`, data);
      await dispatch(slice.actions.getEmployeeListSuccess(response.data.employees));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editContacts(id, data) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.put(`employee/edit/contacts/${id}`, data);
      await dispatch(slice.actions.getEmployeeListSuccess(response.data.employees));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
