// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
// import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  payroll: getIcon('file_type_pdf'),
  advance: getIcon('advance'),
  expenses: getIcon('expenses'),
  payments: getIcon('payments'),
  reports: getIcon('reports'),
  classes: getIcon('classes'),
  students: getIcon('students'),
  settings: getIcon('settings'),
  billing: getIcon('billing'),
  chat: getIcon('ic_chat'),
  user: getIcon('ic_user'),
  dashboard: getIcon('ic_dashboard'),
  ecommerce: getIcon('ic_ecommerce'),
  overview: getIcon('overview')
};

const accountantSidebarConfig = [
  // FEE COLLECTION
  // ----------------------------------------------------------------------
  {
    subheader: 'fee collection',
    items: [
      {
        title: 'overview',
        path: PATH_DASHBOARD.feeCollection.overview,
        icon: ICONS.overview
      },
      {
        title: 'payments',
        path: PATH_DASHBOARD.feeCollection.payments,
        icon: ICONS.ecommerce
      },
      { title: 'classes', path: PATH_DASHBOARD.feeCollection.classes, icon: ICONS.classes },
      { title: 'students', path: PATH_DASHBOARD.feeCollection.students, icon: ICONS.students },
      { title: 'Archived Students', path: PATH_DASHBOARD.feeCollection.archives, icon: ICONS.students },
      { title: 'Deleted Students', path: PATH_DASHBOARD.feeCollection.deletedStudents, icon: ICONS.students },
      { title: 'fee structures', path: PATH_DASHBOARD.feeCollection.feeStructures, icon: ICONS.reports }
      // { title: 'settings', path: PATH_DASHBOARD.feeCollection.settings, icon: ICONS.settings }
    ]
  }
];

export default accountantSidebarConfig;
