import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosAuth from '../../utils/axiosAuth';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  feePaymentList: []
};

const slice = createSlice({
  name: 'feePayment',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // DELETE feePayment
    deleteFeePayment(state, action) {
      const deleteFeePayment = filter(state.employeeList, (employee) => employee._id !== action.payload);
      state.feePaymentList = deleteFeePayment;
    },

    // GET All feePayments
    getFeePaymentListSuccess(state, action) {
      state.isLoading = false;
      state.feePaymentList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteFeePayment } = slice.actions;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getFeePaymentList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.get('feeCollectionDash/fetch_payments');
      dispatch(slice.actions.getFeePaymentListSuccess(response.data.payments));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
