import axios from 'axios';

// ----------------------------------------------------------------------

const axiosAuth = axios.create({
  // sandboxUrl (local pc)
  // baseURL: 'http://localhost:8080'

  // SandboxUrl (local network)
  // baseURL: 'http://192.168.8.101:8080'

  // Production URL
  // baseURL: 'https://hela-server.herokuapp.com'

  // Live URL
  baseURL: 'https://hela.co.ke/myserver'
});

export default axiosAuth;
