import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosAuth from '../../utils/axiosAuth';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  wallet: []
};

const slice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // DELETE Payroll
    deleteLoan(state, action) {
      const deleteLoan = filter(state.wallet, (employee) => employee._id !== action.payload);
      state.wallet = deleteLoan;
    },

    // GET All Salary Advances
    getWalletSuccess(state, action) {
      state.isLoading = false;
      state.wallet = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteLoan } = slice.actions;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getWallet() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.get('wallet/fetch_all');
      dispatch(slice.actions.getWalletSuccess(response.data.wallet));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function topUpMpesa(payload) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.get('wallet/topUp/mpesa', payload);
      dispatch(slice.actions.getWalletSuccess(response.data.wallet));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function topUpBankTransfer(payload) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.get('wallet/topUp/bank_transfer', payload);
      dispatch(slice.actions.getWalletSuccess(response.data.wallet));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function topUpCreditCard(payload) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.get('wallet/topUp/credit_card', payload);
      dispatch(slice.actions.getWalletSuccess(response.data.wallet));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
