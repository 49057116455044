// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  payroll: getIcon('file_type_pdf'),
  advance: getIcon('advance'),
  expenses: getIcon('expenses'),
  payments: getIcon('payments'),
  reports: getIcon('reports'),
  classes: getIcon('classes'),
  students: getIcon('students'),
  settings: getIcon('settings'),
  billing: getIcon('billing'),
  chat: getIcon('ic_chat'),
  user: getIcon('ic_user'),
  dashboard: getIcon('ic_dashboard'),
  ecommerce: getIcon('ic_ecommerce'),
  overview: getIcon('overview')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'dashboard',
        path: PATH_DASHBOARD.general.analytics,
        icon: ICONS.dashboard
      }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Payroll',
    items: [
      // MANAGEMENT : EMPLOYEES
      {
        title: 'employees',
        path: PATH_DASHBOARD.employee.root,
        icon: ICONS.user,
        children: [
          { title: 'employees', path: PATH_DASHBOARD.employee.list },
          { title: 'employee exits', path: PATH_DASHBOARD.employee.exit }
          // { title: 'list', path: PATH_DASHBOARD.user.list },
          // { title: 'create', path: PATH_DASHBOARD.user.newUser },
          // { title: 'edit', path: PATH_DASHBOARD.user.editById },
          // { title: 'account', path: PATH_DASHBOARD.user.account }
        ]
      },

      // MANAGEMENT : PAYROLL
      {
        title: 'payroll',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.payroll,
        children: [
          { title: 'run payroll', path: PATH_DASHBOARD.payroll.list },
          { title: 'past payrolls', path: PATH_DASHBOARD.payroll.past }
          // { title: 'payslips', path: PATH_DASHBOARD.payroll.payslip },
          // { title: 'p9 forms', path: PATH_DASHBOARD.payroll.p9form }
          // { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
          // { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
          // { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
        ]
      },

      // MANAGEMENT : SALARY ADVANCE

      {
        title: 'deductions',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.advance,
        children: [
          { title: 'fees deductions', path: PATH_DASHBOARD.deduction.fees },
          { title: 'salary advance', path: PATH_DASHBOARD.salaryAdvance.root }
          // { title: 'helb', path: PATH_DASHBOARD.deduction.helb },
          // { title: 'loans', path: PATH_DASHBOARD.deduction.loan }
          // { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
          // { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
          // { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
        ]
      },
      // MANAGEMENT : EXPENSES
      // { title: 'expenses', path: PATH_DASHBOARD.chat.root, icon: ICONS.expenses },

      // MANAGEMENT : PAYMENTS
      { title: 'payments', path: PATH_DASHBOARD.paymentsUser.list, icon: ICONS.payments },

      // MANAGEMENT : REPORTS
      {
        title: 'reports',
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.reports,
        children: [
          { title: 'PAYE', path: PATH_DASHBOARD.reports.paye },

          { title: 'NHIF/SHIF', path: PATH_DASHBOARD.reports.nhif },
          { title: 'NSSF', path: PATH_DASHBOARD.reports.nssf },
          { title: 'Housing Levy', path: PATH_DASHBOARD.reports.housingLevy }
          // { title: 'KRA P9', path: PATH_DASHBOARD.reports.p9 },
        ]
      }
    ]
  },

  // FEE COLLECTION
  // ----------------------------------------------------------------------
  {
    subheader: 'fee collection',
    items: [
      {
        title: 'overview',
        path: PATH_DASHBOARD.feeCollection.overview,
        icon: ICONS.overview
      },
      {
        title: 'payments',
        path: PATH_DASHBOARD.feeCollection.payments,
        icon: ICONS.ecommerce
      },
      { title: 'classes', path: PATH_DASHBOARD.feeCollection.classes, icon: ICONS.classes },
      { title: 'students', path: PATH_DASHBOARD.feeCollection.students, icon: ICONS.students },
      { title: 'Archived Students', path: PATH_DASHBOARD.feeCollection.archives, icon: ICONS.students },
      { title: 'Deleted Students', path: PATH_DASHBOARD.feeCollection.deletedStudents, icon: ICONS.students },
      { title: 'fee structures', path: PATH_DASHBOARD.feeCollection.feeStructures, icon: ICONS.reports }
      // { title: 'settings', path: PATH_DASHBOARD.feeCollection.settings, icon: ICONS.settings }
    ]
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'account',
    items: [
      {
        title: 'My Wallet',
        icon: ICONS.billing,
        path: PATH_DASHBOARD.wallet.root,
        info: <Label color="error">2</Label>
      },
      {
        title: 'settings',
        icon: ICONS.settings,
        path: PATH_DASHBOARD.general.analytics
      }
      // {
      //   title: 'deposits',
      //   path: PATH_DASHBOARD.user.account,
      //   icon: ICONS.billing,
      //   info: <Label color="error">2</Label>
      // },
      // { title: 'settings', path: PATH_DASHBOARD.user.account, icon: ICONS.settings }
      // // { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      // // {
      // //   title: 'kanban',
      // //   path: PATH_DASHBOARD.kanban,
      // //   icon: ICONS.kanban
      // }
    ]
  }
];

export default sidebarConfig;
