import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import blogReducer from './slices/blog';
import userReducer from './slices/user';
import employeeReducer from './slices/employee';
import payrollReducer from './slices/payroll';
import salaryAdvanceReducer from './slices/salaryAdvance';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import loanReducer from './slices/loan';
import helbReducer from './slices/helb';
import feePaymentReducer from './slices/feePayment';
import feeClassReducer from './slices/feeClass';
import feeStructureReducer from './slices/feeStructure';
import feeStudentReducer from './slices/feeStudent';
import employmentTypeReducer from './slices/employmentType';
import walletReducer from './slices/wallet';
import exportReducer from './slices/export';
import feeDashDetailsReducer from './slices/feeDashboard';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout']
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  blog: blogReducer,
  user: userReducer,
  employee: employeeReducer,
  payroll: payrollReducer,
  salaryAdvance: salaryAdvanceReducer,
  loan: loanReducer,
  helb: helbReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  feePayment: feePaymentReducer,
  feeClass: feeClassReducer,
  feeStructure: feeStructureReducer,
  feeStudent: feeStudentReducer,
  feeDash: feeDashDetailsReducer,
  employmentType: employmentTypeReducer,
  wallet: walletReducer,
  export: exportReducer,
  product: persistReducer(productPersistConfig, productReducer)
});

export { rootPersistConfig, rootReducer };
