import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box, Stack, Link, Typography, Card, CardContent, Grid } from '@mui/material';
// routes
import { PATH_CREATE } from '../../routes/paths';

const ClientTypeComponent = () => {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;

  return (
    <div>
      <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography align="center" variant="body2" gutterBottom sx={{ color: 'text.secondary' }}>
            Welcome Back!
          </Typography>
          <Typography align="center" variant="h4" gutterBottom>
            What kind of client are you?
          </Typography>
        </Box>
      </Stack>

      <Stack direction="row">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Link underline="none" component={RouterLink} to={PATH_CREATE.company}>
              <Card variant="outlined" sx={{ borderRadius: 1 }}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    I'm a company
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    Your company information will be used on invoices and tax documentation.
                  </Typography>

                  <Typography sx={{ fontSize: 12, color: PRIMARY_MAIN }}>Select</Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>

          <Grid item xs={12} md={6}>
            <Link underline="none" component={RouterLink} to={PATH_CREATE.individial}>
              <Card variant="outlined" sx={{ borderRadius: 1 }}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    I'm an individual
                  </Typography>

                  <Typography variant="body2" gutterBottom>
                    Your full information will be used on invoices and tax documentation.
                  </Typography>

                  <Typography sx={{ fontSize: 12, color: PRIMARY_MAIN }}>Select</Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        </Grid>
      </Stack>
    </div>
  );
};

export default ClientTypeComponent;
