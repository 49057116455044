// import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosAuth from '../../utils/axiosAuth';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  payrollList: []
};

const slice = createSlice({
  name: 'payroll',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // DELETE Payroll
    // deletePayroll(state, action) {
    //   const deletePayroll = filter(state.payrollList, (payroll) => payroll._id !== action.payload);
    //   state.payrollList = deletePayroll;
    // },

    // GET All Payrolls
    getPayrollListSuccess(state, action) {
      state.isLoading = false;
      state.payrollList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow } = slice.actions;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getPayrollList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.get('payroll/fetch_all');
      dispatch(slice.actions.getPayrollListSuccess(response.data.payrolls));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function approvePayroll(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.put(`payroll/approve/${id}`);
      dispatch(slice.actions.getPayrollListSuccess(response.data.payrolls));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function disapprovePayroll(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.put(`payroll/disapprove/${id}`);
      dispatch(slice.actions.getPayrollListSuccess(response.data.payrolls));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deletePayroll(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.delete(`payroll/delete/${id}`);
      dispatch(slice.actions.getPayrollListSuccess(response.data.payrolls));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function markAsPaid(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.put(`payroll/mark_as_paid/${id}`);
      dispatch(slice.actions.getPayrollListSuccess(response.data.payrolls));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function makePayments(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.put(`payroll/pay/${id}`);
      dispatch(slice.actions.getPayrollListSuccess(response.data.payrolls));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function sendPayslips(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.put(`payroll/send_payslips/${id}`);
      dispatch(slice.actions.getPayrollListSuccess(response.data.payrolls));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
