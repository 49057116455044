import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts

import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import AccGuard from '../guards/AccGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import useAuth from '../hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user } = useAuth();
  let rootLink = '/dashboard/analytics';

  if (user?.status === 'accountant' || user?.status === 'Accountant') {
    rootLink = '/dashboard/feeCollection/overview';
  }

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        // {
        //   path: 'register',
        //   element: (
        //     <GuestGuard>
        //       <Register />
        //     </GuestGuard>
        //   )
        // },
        { path: 'login-unprotected', element: <Login /> },
        // { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'update_password/:token', element: <UpdatePassword /> }
      ]
    },

    // Create Account Routes
    {
      path: 'create-account',
      element: (
        <AuthGuard>
          <ClientType />
        </AuthGuard>
      )
    },

    {
      path: 'create-account/individual',
      element: (
        <AuthGuard>
          <IndividualDetails />
        </AuthGuard>
      )
    },
    {
      path: 'create-account/company',
      element: (
        <AuthGuard>
          <CompanyDetails />
        </AuthGuard>
      )
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <AccGuard>
            <DashboardLayout />
          </AccGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={rootLink} replace /> },
        { path: 'app', element: <GeneralApp /> },
        { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace /> },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
            { path: 'invoice', element: <EcommerceInvoice /> }
          ]
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> }
          ]
        },

        {
          path: 'employee',
          children: [
            { element: <Navigate to="/dashboard/employee/list" replace /> },
            { path: 'exit', element: <EmployeeExit /> },
            { path: 'list', element: <EmployeeList /> },
            { path: 'new', element: <EmployeeCreate /> },
            { path: 'edit/:elementId', element: <EmployeeEdit /> },
            { path: 'view/:elementId', element: <EmployeeProfile /> }
          ]
        },

        {
          path: 'payroll',
          children: [
            { element: <Navigate to="/dashboard/payroll/list" replace /> },
            { path: 'list', element: <PayrollList /> },
            { path: 'past', element: <PayrollPast /> },
            { path: 'payslip', element: <PayrollPayslip /> },
            { path: 'p9form', element: <PayrollP9 /> },
            { path: 'view/:elementId', element: <PayrollProfile /> }
          ]
        },

        {
          path: 'deduction',
          children: [
            { element: <Navigate to="/dashboard/deduction/loan" replace /> },
            // { path: 'helb', element: <DeductionHelb /> },
            // { path: 'loan', element: <DeductionLoan /> }
            { path: 'fees', element: <DeductionFees /> }
          ]
        },

        {
          path: 'salaryAdvance',
          children: [
            { element: <Navigate to="/dashboard/salaryAdvance/list" replace /> },
            { path: 'list', element: <SalaryAdvanceList /> },
            { path: 'profile', element: <SalaryAdvanceProfile /> }
          ]
        },

        {
          path: 'paymentsUser',
          children: [
            { element: <Navigate to="/dashboard/paymentsUser/list" replace /> },
            { path: 'list', element: <PaymentsUser /> }
          ]
        },

        {
          path: 'reports',
          children: [
            { element: <Navigate to="/dashboard/reports/nhif" replace /> },
            { path: 'nhif', element: <ReportsNHIF /> },
            { path: 'nssf', element: <ReportsNSSF /> },
            { path: 'paye', element: <ReportsPAYE /> },
            { path: 'housingLevy', element: <ReportsHousingLevy /> }
            // { path: 'p9', element: <ReportsP9 /> }
          ]
        },

        {
          path: 'feeCollection',
          children: [
            { element: <Navigate to="/dashboard/feeCollection/overview" replace /> },
            { path: 'overview', element: <FeeOverview /> },
            { path: 'payments', element: <FeePayments /> },
            { path: 'classes', element: <FeeClasses /> },
            { path: 'students', element: <FeeStudents /> },
            { path: 'archives', element: <FeeArchives /> },
            { path: 'deletedStudents', element: <FeeDeleted /> },
            { path: 'feeStructures', element: <FeeStructures /> },
            { path: 'feeStructures/view/:structureId', element: <FeeStructuresView /> },
            { path: 'feeStructures/view/term/:structureId/:termId', element: <FeeStructuresViewTerm /> },
            { path: 'settings', element: <FeeSettings /> },

            { path: 'students/edit/:elementId', element: <FeeStudentsEdit /> },
            { path: 'students/view/:elementId', element: <FeeStudentsProfile /> },
            { path: 'archives/view/:elementId', element: <FeeArchivesProfile /> },
            { path: 'deletedStudents/view/:elementId', element: <FeeDeletedProfile /> },
            { path: 'classes/view/:elementId', element: <FeeClassesProfile /> }
          ]
        },

        {
          path: 'blog',
          children: [
            { element: <Navigate to="/dashboard/blog/posts" replace /> },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'new-post', element: <BlogNewPost /> }
          ]
        },
        {
          path: 'mail',
          children: [
            { element: <Navigate to="/dashboard/mail/all" replace /> },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> }
          ]
        },
        {
          path: 'chat',
          children: [
            { element: <Chat /> },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> }
          ]
        },
        { path: 'wallet', element: <Wallet /> },
        { path: 'calendar', element: <Calendar /> },
        { path: 'kanban', element: <Kanban /> }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },

    {
      path: '/',
      element: (
        <AccGuard>
          <Navigate to="/dashboard" replace />
        </AccGuard>
      )
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
// const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const UpdatePassword = Loadable(lazy(() => import('../pages/authentication/UpdatePassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));

// Create Account
const ClientType = Loadable(lazy(() => import('../pages/account-creation/ClientType')));
const CompanyDetails = Loadable(lazy(() => import('../pages/account-creation/CompanyDetails')));
const IndividualDetails = Loadable(lazy(() => import('../pages/account-creation/IndividualDetails')));

// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const EcommerceInvoice = Loadable(lazy(() => import('../pages/dashboard/EcommerceInvoice')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

const EmployeeExit = Loadable(lazy(() => import('../pages/dashboard/EmployeeExit')));
const EmployeeList = Loadable(lazy(() => import('../pages/dashboard/EmployeeList')));
const EmployeeCreate = Loadable(lazy(() => import('../pages/dashboard/EmployeeCreate')));
const EmployeeEdit = Loadable(lazy(() => import('../pages/dashboard/EmployeeEdit')));
const EmployeeProfile = Loadable(lazy(() => import('../pages/dashboard/EmployeeProfile')));

const PayrollList = Loadable(lazy(() => import('../pages/dashboard/PayrollList')));
const PayrollPast = Loadable(lazy(() => import('../pages/dashboard/PayrollPast')));
const PayrollPayslip = Loadable(lazy(() => import('../pages/dashboard/PayrollPayslip')));
const PayrollP9 = Loadable(lazy(() => import('../pages/dashboard/PayrollP9')));
const PayrollProfile = Loadable(lazy(() => import('../pages/dashboard/PayrollProfile')));

const SalaryAdvanceList = Loadable(lazy(() => import('../pages/dashboard/SalaryAdvanceList')));
const SalaryAdvanceProfile = Loadable(lazy(() => import('../pages/dashboard/SalaryAdvanceProfile')));

// const DeductionHelb = Loadable(lazy(() => import('../pages/dashboard/DeductionHelb')));
// const DeductionLoan = Loadable(lazy(() => import('../pages/dashboard/DeductionLoan')));
const DeductionFees = Loadable(lazy(() => import('../pages/dashboard/DeductionFees')));

const PaymentsUser = Loadable(lazy(() => import('../pages/dashboard/PaymentsUser')));

const ReportsNHIF = Loadable(lazy(() => import('../pages/dashboard/ReportsNhif')));
const ReportsNSSF = Loadable(lazy(() => import('../pages/dashboard/ReportsNssf')));
// const ReportsP9 = Loadable(lazy(() => import('../pages/dashboard/ReportsP9')));
const ReportsPAYE = Loadable(lazy(() => import('../pages/dashboard/ReportsPaye')));
const ReportsHousingLevy = Loadable(lazy(() => import('../pages/dashboard/ReportsHousingLevy')));

const FeeOverview = Loadable(lazy(() => import('../pages/dashboard/FeeOverview')));
const FeePayments = Loadable(lazy(() => import('../pages/dashboard/FeePayments')));
const FeeClasses = Loadable(lazy(() => import('../pages/dashboard/FeeClasses')));
const FeeStructures = Loadable(lazy(() => import('../pages/dashboard/FeeStructures')));
const FeeStructuresView = Loadable(lazy(() => import('../pages/dashboard/FeeStructuresView')));
const FeeStructuresViewTerm = Loadable(lazy(() => import('../pages/dashboard/FeeStructuresViewTerm')));

const FeeClassesProfile = Loadable(lazy(() => import('../pages/dashboard/FeeClassesProfile')));
const FeeStudents = Loadable(lazy(() => import('../pages/dashboard/FeeStudents')));
const FeeArchives = Loadable(lazy(() => import('../pages/dashboard/FeeArchives')));
const FeeDeleted = Loadable(lazy(() => import('../pages/dashboard/FeeDeleted')));

const FeeStudentsEdit = Loadable(lazy(() => import('../pages/dashboard/FeeStudentsEdit')));
const FeeStudentsProfile = Loadable(lazy(() => import('../pages/dashboard/FeeStudentsProfile')));
const FeeArchivesProfile = Loadable(lazy(() => import('../pages/dashboard/FeeArchivesProfile')));
const FeeDeletedProfile = Loadable(lazy(() => import('../pages/dashboard/FeeDeletedProfile')));

const FeeSettings = Loadable(lazy(() => import('../pages/dashboard/FeeSettings')));

const Wallet = Loadable(lazy(() => import('../pages/dashboard/Wallet')));

const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Main

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
