// import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosAuth from '../../utils/axiosAuth';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  salaryAdvanceList: []
};

const slice = createSlice({
  name: 'salaryAdvance',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // DELETE Payroll
    // deleteSalaryAdvance(state, action) {
    //   const deleteSalaryAdvance = filter(state.salaryAdvanceList, (employee) => employee._id !== action.payload);
    //   state.salaryAdvanceList = deleteSalaryAdvance;
    // },

    // GET All Salary Advances
    getSalaryAdvanceListSuccess(state, action) {
      state.isLoading = false;
      state.salaryAdvanceList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { onToggleFollow, deleteSalaryAdvance } = slice.actions;

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export function getSalaryAdvanceList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.get('salary_advance/fetch_all');
      dispatch(slice.actions.getSalaryAdvanceListSuccess(response.data.advances));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function approvePaySalaryAdvance(advanceId) {
  return async (dispatch) => {
    console.log(advanceId);

    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosAuth.put(`salary_advance/approve/${advanceId}`);
      dispatch(slice.actions.getSalaryAdvanceListSuccess(response.data.advances));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteSalaryAdvance(advanceId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    console.log(advanceId);
    try {
      const response = await axiosAuth.delete(`salary_advance/delete/${advanceId}`);
      dispatch(slice.actions.getSalaryAdvanceListSuccess(response.data.advances));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
